import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AHRService, AuthenticatedIdentity, AuthenticationService, Case, CaseFiles, CurrentUser, Option, ReviewerCase, WFACaseInfo } from '../../../domain';
import { ConsoleService } from '../../../widgets/console.service';
import { ReviewerService } from '../reviewer.service';

@Component({
  selector: 'reviewer-case-detail',
  templateUrl: './reviewer-case-detail.component.html',
  styleUrls: ['./reviewer-case-detail.component.scss']
})
export class ReviewerCaseDetailComponent implements OnInit {

	case: ReviewerCase = undefined;
	fullCase: Case = undefined;
	caseFiles = { }; //Record<number, CaseFiles> = undefined;
	firstCaseYear: number;
	currentUser: Option<CurrentUser> = Option.create<CurrentUser>();
	reviewerType: string = "";

	wfaCase = Option.create<WFACaseInfo>();
	_token:any="";
	constructor(
		private route: ActivatedRoute,
		private svc: AHRService,
		private console: ConsoleService,
		private authSvc: AuthenticationService,
		private reviewerService: ReviewerService
	) { }

	completeForMostRecentRole() {
		return !this.case ? false : false;//TODO
	}

	ngOnInit() {
		
		this.authSvc.currentIdentity.subscribe(i => {
			this._token = i.map(i => i.token);
			i.match(
				() => {
					this.currentUser = Option.create<CurrentUser>();
				},
				(i: AuthenticatedIdentity) => {
					this.currentUser = Option.create<CurrentUser>(i.user);
				});
		});

		let $academicId = this.route.params.pipe(map((params: Params) => params['academicId']));

		this.reviewerService.reviewer$.subscribe((type) => {
			this.reviewerType = type;
		});

		$academicId.pipe(mergeMap(academicId => from(this.svc.getCaseToBeReviewedCurrentUser(academicId))))
			.subscribe(_case => {
				this.case = _case.getValueOrThrow();
				if (this.case.caseHistory && this.case.caseHistory[0] && this.case.caseHistory[0].periodOfReviewEndingInYear) {
					this.firstCaseYear = this.case.caseHistory[0].periodOfReviewEndingInYear;
				  } else {
					this.firstCaseYear = this.case.id.periodOfReviewEndingInYear;
				  }
				// console.log(this.case.periodOfReview.value.end.getFullYear());
				this.console.log('reviewer-case-detail arguments:', this.case.id.academicId, _case);

				this.reviewerService.updateAcademicId(this.case.id.academicId)

				this.svc.tryGetWFACaseInfo(this.case.academicId).then(c => 
					this.wfaCase = c);

				this.loadCaseFiles(this.case.id);
			});
	}

	public reviewerCanSeeCaseHistory() {
		// AHR, Supervisors and AVP can see case history
		//this.console.log("Show Case History: ", this.case.yourSteps.some(step => step.id == "Supervisors" || step.id == "FinalReviewer"));

		if (this.currentUser.value.roles.academicHumanResources)
			return true;

		return this.case.yourSteps.some(step => step.id == "Supervisors" || step.id == "FinalReviewer" );
	}

	public isReadOnlyStep(){
		return this.case.yourSteps.some(step => step.readOnlyStep);
	}

	public loadCaseFilesIfNeeded(caseId) {
		this.loadCaseFiles(caseId);
	}

	public loadCaseFiles(caseId) {
		this.svc.getCaseFiles(caseId).subscribe(files => {

			this.caseFiles[caseId.periodOfReviewEndingInYear] = files as CaseFiles;

			// only show review files that a reviewer is allowed to see. See: AHRDashboardReadModel.canReadReviewFilesFromReviewStepIds for logic
			this.caseFiles[caseId.periodOfReviewEndingInYear].reviewerFiles = 
				this.caseFiles[caseId.periodOfReviewEndingInYear].reviewerFiles
				.filter(file => 
					file.committeeName == "" 
					|| this.caseFiles[caseId.periodOfReviewEndingInYear].canReadReviewFilesFromCommitteeNames.includes(file.committeeName)
					);
		});
	}

	public caseFilesExist(periodOfReviewYear) {
		return !!this.caseFiles && this.caseFiles[periodOfReviewYear] != undefined;
	}

	public caseHistoryIsFirst(caseYear: number){
		if(caseYear == this.firstCaseYear) return true;
		return false;
	}

	public navigateToWFA(url){
		let obj:any=Object();
		obj.accessToken=this._token;
		const userClaims = localStorage.getItem("projectboard.user.claims");
		url = url+'?projectBoard='+encodeURI(userClaims);
		window.location.href=url;
	}
}