<ng-container [ngSwitch]="!!caseAdvancement && !!caseAdvancement.proposedAction.value">
    <ng-container *ngSwitchCase="true">
        <ng-container [ngSwitch]="IsIndefiniteStatusOnly()">
            <ng-container *ngSwitchCase="true">
                Indefinite Status
            </ng-container>
            <ng-container *ngSwitchCase="false">
                {{caseAdvancement.proposedAction.value.type | actionTypeName}}
                <span [ngSwitch]="caseAdvancement.proposedAction.value.exceptionalTiming.value">
                    <span *ngSwitchCase="ExceptionalTiming.MonthRule13">
                        (13-mo rule)
                    </span>
                    <span *ngSwitchCase="ExceptionalTiming.MonthRule24">
                        (24-mo rule)
                    </span>
                    <span *ngSwitchCase="ExceptionalTiming.MonthRule18">
                        (18-mo rule)
                    </span>
                    <span *ngSwitchCase="ExceptionalTiming.MonthRule30">
                        (30-mo rule)
                    </span>                                        
                    <span *ngSwitchCase="ExceptionalTiming.Acceleration">
                        (accelerated)
                    </span>
                </span>
                <span *ngIf="indefiniteStatusProposed()">
                    (Indefinite Status)
                </span>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="false" class="text-muted">
        None Yet
    </ng-container>
</ng-container>